@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #000;
  font-family: "Syne", sans-serif;
}

.main {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.title {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  font-size: 18px;
}

.title h1 {
  font-weight: 400;
  color: white;
  margin: 0;
  text-align: center;
}

.title p {
  color: grey;
  margin: 0;
  text-align: center;
  margin-top: 10px;
}

.plane {
  width: 100%;
  height: 100%;
  position: absolute;
}

.plane img {
  position: absolute;
}

.plane:nth-of-type(1) {
  filter: brightness(0.7);
}

.plane:nth-of-type(1) img:nth-of-type(1) {
  left: 90%;
  top: 70%;
}

.plane:nth-of-type(1) img:nth-of-type(2) {
  left: 5%;
  top: 65%;
}

.plane:nth-of-type(1) img:nth-of-type(3) {
  left: 35%;
  top: 0;
}

.plane:nth-of-type(2) {
  filter: brightness(0.6);
}

.plane:nth-of-type(2) img:nth-of-type(1) {
  left: 5%;
  top: 10%;
}

.plane:nth-of-type(2) img:nth-of-type(2) {
  left: 80%;
  top: 5%;
}

.plane:nth-of-type(2) img:nth-of-type(3) {
  left: 60%;
  top: 60%;
}

.plane:nth-of-type(3) {
  filter: brightness(0.5);
}

.plane:nth-of-type(3) img:nth-of-type(1) {
  left: 65%;
  top: 2.5%;
}

.plane:nth-of-type(3) img:nth-of-type(2) {
  left: 40%;
  top: 75%;
}
.custom-underline{
  text-decoration: underline;
  text-decoration-color: #a0cf3b;
}
